import React from 'react'
import { HeroInterface } from "../types"
import styles from "./Hero.module.css"

const Hero = ({ alt, bg, body, subtitle, title, button_link, button_text}: HeroInterface) => {
    return (
        <header>
            {bg && alt &&
                <div className={styles.background}>
                    <img src={require("../assets/images/backgrounds/" + title + ".webp")} alt={alt} />
                </div>
            }
            <div className={styles.content}>
                <h1 className={styles.title}>{title}</h1>
                <small className={styles.subtitle}>{subtitle}</small>
                <p className={styles.body}>{body}</p>
                {button_link && button_text && <a className={styles.button} href={button_link}>{button_text}</a>}
            </div>
        </header>
    )
}

export default Hero
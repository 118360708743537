import React from 'react';
import '../page.css';
import Song from '../components/Song';
import Hero from '../components/Hero';
// import Button from '../components/Button';

const today = new Date();


function check_if_new(date: any) {
  return (
    (today.getFullYear() === date.year) &&
    (today.getMonth() >= date.month - 1 && today.getMonth() <= date.month));
}

function Music() {
  const music = require('../assets/data/music.json');
  return (
    <main>
      <Hero
        title='music'
        subtitle='producer and artist based in columbus, ohio.'
        body="since 2016, i've been slowly building a chaotic sound that's unique to me. i make beats, dnb, mixes, and whatever sounds good to me. i draw inspiration from so many artists but the most important to me are madlib, earl sweatshirt, the distillers, machine girl, venetian snares, and aphex twin."
        bg={true}
        alt='a chaotic image of fl studio, with many plugins open at once crowding the screen'
        button_text='listen now'
        button_link='#releases'
      />
      <section id='releases'>
        <h2>releases</h2>
        <div className="card-container">
          {music.releases.map((release: any, index: React.Key) => (
            <Song
              title={release.title}
              subtitle={release.type}
              date={release.date.string}
              featured={index === 0}
              alt={release.alt}
              indicator={check_if_new(release.date) && index === 0 ? 'new release' : undefined}
              image={require("../assets/images/releases/" + release.title + ".webp")}
              apple={release.apple}
              youtube={release.youtube}
              bandcamp={release.bandcamp}
              soundcloud={release.soundcloud}
              key={index}
            />
          ))}
        </div>
      </section>
      <section id='playlists'>
        <h2>playlists</h2>
        <div className="card-container">
          {music.playlists.map((playlist: any) => (
            <Song
              title={playlist.title}
              date={playlist.desc}
              alt={playlist.alt}
              image={require("../assets/images/playlists/" + playlist.title + ".png")}
              apple={playlist.apple}
              soundcloud={playlist.soundcloud}
              key={playlist.title}
            />
          ))}
        </div>
      </section>
    </main>
  );
}

export default Music;

import React from "react"
import { ButtonInterface } from "../types"
import styles from "./Button.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"

const Button = ({ text, filled, type, href, icon, aria_label }: ButtonInterface) => {
    const textElement = text ? <span>{text}</span> : "";
    const iconElement = icon ? getIconElement(icon.toLowerCase()) : "";
    const filledClass = filled ? styles.filled : "";
    return (
        <a aria-label={aria_label} href={href} className={`${styles.btn} ${styles[type.toLowerCase()]} ${filledClass}`}>
            {text &&
                <span aria-hidden='true'>
                    {textElement}
                </span>
            }
            {icon &&
                <span aria-hidden='true'>
                    {iconElement}
                </span>
            }
        </a>
    )
}
export default Button

function getIconElement(icon: String) {
    let iconElement;
    switch (icon) {
        case "spotify":
            iconElement = <FontAwesomeIcon icon={fab.faSpotify} />
            break;
        case "apple":
            iconElement = <FontAwesomeIcon icon={fas.faMusic} />
            break;
        case "soundcloud":
            iconElement = <FontAwesomeIcon icon={fab.faSoundcloud} />
            break;
        case "bandcamp":
            iconElement = <FontAwesomeIcon icon={fab.faBandcamp} />
            break;
        case "youtube":
            iconElement = <FontAwesomeIcon icon={fab.faYoutube} />
            break;
        case "github":
            iconElement = <FontAwesomeIcon icon={fab.faGithub} />
            break;
        case "instagram":
            iconElement = <FontAwesomeIcon icon={fab.faInstagram} />
            break;
        default:
            <FontAwesomeIcon icon={fas.faCircleExclamation} />
            break;
    }

    return iconElement;
}
import React from 'react'
import Hero from '../components/Hero';
// import Button from '../components/Button';

function Contact() {
    // const hero_btn =
    //     <Button
    //         href='https://instagram.com/rali9000'
    //         text='instagram'
    //         type='tertiary'
    //         filled={true}
    //         icon='instagram'
    //         aria_label='instagram'
    //     />;
    return (
        <Hero
            title='contact'
            subtitle='columbus, ohio'
            body="student, programmer, artist, musician."
            bg={true}
            alt='the december cover art, open in photoshop'
            // button={hero_btn}
        />
    )
}

export default Contact;
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Music from './pages/Music';
import Art from './pages/Art';
import Code from './pages/Code';
import Contact from './pages/Contact';
import Navbar from './components/Navbar';

function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/music' element={<Music />} />
                <Route path='/art' element={<Art />} />
                <Route path='/code' element={<Code />} />
                <Route path='/contact' element={<Contact />} />
            </Routes>
        </Router>
    )
}

export default App;
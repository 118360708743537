import React from 'react'
import Hero from '../components/Hero';

function Home() {
    return (
        <Hero
            title='rali'
            subtitle='columbus, ohio'
            body="student, programmer, artist, musician."
            bg={true}
            alt='the december cover art, open in photoshop'
        />
    )
}

export default Home;
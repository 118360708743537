import React from 'react'
import Hero from '../components/Hero';
import Card from '../components/Card';

function Code() {
    const code = require('../assets/data/code.json');
    return (
        <main>
            <Hero
                title='code'
                subtitle='columbus, ohio'
                body="student, programmer, artist, musician."
                bg={true}
                alt='an image of code from the Cyberball project'
            />
            <section id='projects'>
                <h2>Projects</h2>
                <div className="card-container">
                    {code.projects.map((project: any) => (
                        <Card
                            title={project.title}
                            body={project.desc}
                            alt={project.alt}
                            image={require("../assets/images/projects/" + project.title + ".webp")}
                            github={project.github}
                            key={project.title}
                        />
                    ))}
                </div>
            </section>
        </main>
    )
}

export default Code;
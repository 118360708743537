import React from 'react'
import { CardInterface } from "../types"
import Button from './Button';
import styles from "./Card.module.css"

const Card = ({ alt, body, title, indicator, image, subtitle, subtitle_link, apple, spotify, youtube, bandcamp, soundcloud, github }: CardInterface) => {
    return (
        <article className={`${styles.card}`}>
            {indicator &&
                <small className={styles.indicator}>{indicator.toUpperCase()}</small>
            }
            <div className={styles.card_top}>
                {image && alt &&
                    <img loading='lazy' draggable='false' src={image} alt={alt} className={styles.image} />
                }
                <h3 className={styles.title}>{title}</h3>
                {subtitle_link ? (
                        <small className={styles.subtitle}>
                            <a href={subtitle_link}>{subtitle}</a>
                        </small>
                    ) : (
                        <small className={styles.subtitle}>{subtitle}</small>
                    )
                }
            </div>
            <div className={styles.card_bottom}>
                <p className={styles.body}>{body}</p>
                <div className={styles.btn_container}>
                    {apple &&
                        <Button
                            text=""
                            type="primary"
                            href={apple}
                            icon="apple"
                            filled={true}
                            aria_label="apple music"
                        />
                    }
                    {spotify &&
                        <Button
                            text=""
                            type="primary"
                            href={spotify}
                            icon="spotify"
                            filled={true}
                            aria_label="spotify"
                        />
                    }
                    {youtube &&
                        <Button
                            text=""
                            type="primary"
                            href={youtube}
                            icon="youtube"
                            filled={true}
                            aria_label="youtube"
                        />
                    }
                    {bandcamp &&
                        <Button
                            text=""
                            type="primary"
                            href={bandcamp}
                            icon="bandcamp"
                            filled={true}
                            aria_label="bandcamp"
                        />
                    }
                    {soundcloud &&
                        <Button
                            text=""
                            type="primary"
                            href={soundcloud}
                            icon="soundcloud"
                            filled={true}
                            aria_label="soundcloud"
                        />
                    }
                    {github &&
                        <Button
                            text=""
                            type="primary"
                            href={github}
                            icon="github"
                            filled={true}
                            aria_label="apple music"
                        />
                    }
                </div>
            </div>
        </article>
    )
}
export default Card

import React, { useState } from 'react'
import styles from "./Navbar.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons"

const Navbar = () => {
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        (window.scrollY >= 120) ? setNavbar(true) : setNavbar(false);
    }

    window.addEventListener("scroll", changeBackground)

    return (
        <nav className={navbar ? `${styles.navbar} ${styles.active}` : `${styles.navbar}`}>
            {/* <span className={styles.logo}></span> */}
            <ul className={styles.nav_link_list}>
                <li>
                    <a draggable={false} href="/" aria-label="home" className={styles.nav_link}>
                        <span aria-hidden="true">home</span>
                        <FontAwesomeIcon aria-hidden="true" icon={fas.faHome} />
                    </a>
                </li>
                <li>
                    <a draggable={false} href="./music" aria-label="music" className={styles.nav_link}>
                        <span aria-hidden="true">music</span>
                        <FontAwesomeIcon aria-hidden="true" icon={fas.faMusic} />
                    </a>
                </li>
                <li>
                    <a draggable={false} href="./art" aria-label="art" className={styles.nav_link}>
                        <span aria-hidden="true">art</span>
                        <FontAwesomeIcon aria-hidden="true" icon={fas.faPalette} />
                    </a>
                </li>
                <li>
                    <a draggable={false} href="./code" aria-label="code" className={styles.nav_link}>
                        <span aria-hidden="true">code</span>
                        <FontAwesomeIcon aria-hidden="true" icon={fas.faCode} />
                    </a>
                </li>
                <li>
                    <a draggable={false} href="./contact" aria-label="contact" className={styles.nav_link}>
                        <span aria-hidden="true">contact</span>
                        <FontAwesomeIcon aria-hidden="true" icon={fas.faEnvelope} />
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Navbar
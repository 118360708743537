import React from 'react';
import '../page.css';
import Card from '../components/Card';
import Hero from '../components/Hero';
// import Button from '../components/Button';
import Song from '../components/Song';

function Art() {
  const art = require('../assets/data/art.json');
  // const hero_btn = <Button href='#traditional' text='look at my latest pieces' type='tertiary' filled={true} />;
  return (
    <main>
      <Hero
        title='art'
        subtitle='artist based in columbus, ohio.'
        body="i've been drawing for as long as i can remember. in 2018 i started really taking traditional art seriously. i usually use charcoal, graphite, and ink, but i also use acrylic and oil paints. in 2015 i got into photoshop and graphic design, and in 2019 i got an ipad and started learning digital painting."
        bg={true}
        alt='an image of the cover art for my song "december" in photoshop'
        // button={hero_btn}
      />
      <section id='traditional'>
        <h2>traditional</h2>
        <div className="card-container art">
          {art.traditional.map((traditional: any) => (
            <Card
              title={traditional.title}
              subtitle={traditional.media}
              body={traditional.date.year}
              alt={traditional.alt}
              image={require("../assets/images/traditional/" + traditional.title + " " + traditional.date.year + ".webp")}
              key={traditional.title + traditional.date.year}
            />
          ))}
        </div>
      </section>
      <section id='commissions'>
        <h2>commissions</h2>
        <div className="card-container">
          {art.commissions.map((commission: any) => (
            <Song
              title={commission.title}
              subtitle={commission.artist}
              subtitle_link={commission.artist_link}
              // body={commission.date.string}
              alt={commission.alt}
              image={require("../assets/images/commissions/" + commission.title + " " + commission.date.year + ".webp")}
              apple={commission.apple}
              spotify={commission.spotify}
              youtube={commission.youtube}
              // bandcamp={commission.bandcamp}
              soundcloud={commission.soundcloud}
              key={commission.title + commission.date.year}
            />
          ))}
        </div>
      </section>
    </main>
  );
}

export default Art;

import React from 'react'
import { SongInterface } from "../types"
import styles from "./Song.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"


const Song = ({ image, indicator, alt, title, subtitle, subtitle_link, date, featured, apple, youtube, soundcloud, bandcamp, spotify }: SongInterface) => {
    const props = (link: string | undefined, label: string) => {
        if (!label) throw new Error('ERROR: Label must be defined');
        else {
            if (link === '' || link === undefined) return {
                tabIndex: -1,
                className: `${styles.unavailable}`,
                'aria-hidden': true,
                'aria-label': undefined,
                href: undefined,
                title: label
            };
            else return {
                tabIndex: undefined,
                className: undefined,
                'aria-hidden': false,
                'aria-label': label,
                href: link,
                title: label
            };
        }
    }

    const buttons = () => {
        if (spotify !== undefined)
            return (
                <div className={`${styles.buttons} ${styles.card_bottom_hover}`}>
                    <a {...props(apple, 'apple music')}><FontAwesomeIcon icon={fas.faMusic} /></a>
                    <a {...props(spotify, 'spotify')}><FontAwesomeIcon icon={fab.faSpotify} /></a>
                    <a {...props(youtube, 'youtube')}><FontAwesomeIcon icon={fab.faYoutube} /></a>
                    <a {...props(soundcloud, 'soundcloud')}><FontAwesomeIcon icon={fab.faSoundcloud} /></a>
                </div>
            )
        else
            return (
                <div className={`${styles.buttons} ${styles.card_bottom_hover}`}>
                    <a {...props(apple, 'apple music')}><FontAwesomeIcon icon={fas.faMusic} /></a>
                    <a {...props(youtube, 'youtube')}><FontAwesomeIcon icon={fab.faYoutube} /></a>
                    <a {...props(soundcloud, 'soundcloud')}><FontAwesomeIcon icon={fab.faSoundcloud} /></a>
                    <a {...props(bandcamp, 'bandcamp')}><FontAwesomeIcon icon={fab.faBandcamp} /></a>
                </div>
            )
    }

    return (
        <article className={featured ? `${styles.card} ${styles.featured}` : `${styles.card}`}>
            <img loading='lazy' draggable='false' src={image} alt={alt} className={styles.image} />
            {indicator && <strong className={styles.indicator}>{indicator}</strong>}
            <div className={styles.card_bottom}>
                <div className={styles.text}>
                    <strong className={styles.title}>{title}</strong>
                    {((subtitle && subtitle_link) && (subtitle))
                        ? <a href={subtitle_link} className={`${styles.subtitle_link} ${styles.card_bottom_hover}`}><small><strong>{subtitle}</strong></small></a>
                        : <p className={`${styles.subtitle} ${styles.card_bottom_hover}`}><small><strong>{subtitle}</strong></small></p>}
                    {date && <p className={`${styles.date} ${styles.card_bottom_hover}`}>{date}</p>}
                </div>
                {buttons()}
            </div>
            <img loading='lazy' draggable='false' src={image} alt='' aria-hidden className={styles.background} />
        </article>
    )
}

export default Song